import {createContext, useEffect, useState} from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const getInitialLanguage = () => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      return storedLanguage;
    }

    const browserLanguage = navigator.language;
    if (browserLanguage.startsWith("fr")) {
      return "fr_CA";
    }

    return "en_US";
  };

  const [language, setLanguage] = useState(getInitialLanguage);

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;

import React, {useCallback, useContext, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import DisplayList from "../../components/display-list/DisplayList";
import UserContext from "../../contexts/UserContexts";
import ListHeader from "../../components/list-header";
import {PATH_CONSTANTS} from "../../constants/pathConstants";
import {fetchMembersList} from "../../utils/api";
import {useAdminPortalTranslation} from "../../api/translationApi";
import LanguageContext from "../../contexts/LanguageContext";

const customerColumns = [
  { dataField: (item) => item.firstName },
  { dataField: (item) => item.lastName },
  { dataField: (item) => item.status },
  { dataField: (item) => item.memberType },
  { dataField: (item) => item.phoneNumber },
  { dataField: (item) => item.email },
];

const CustomerList = () => {
  const { businessId } = useParams();
  const [customers, setCustomers] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const {
    jwtToken,
    isLoading: isUserLoading,
    userGroupPrefix,
  } = useContext(UserContext);
  const type = "ME";

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);

  const memberTypeString =
    language === "en_US" ? "Member Type" : "Détails commerciaux";

  const headers = [
    adminPortalTranslation.business_first_name,
    adminPortalTranslation.business_last_name,
    adminPortalTranslation.subscription_active,
    memberTypeString,
    adminPortalTranslation.business_phone_number,
    adminPortalTranslation.business_email_address,
    adminPortalTranslation.product_header_action,
  ];

  const [selectedItem] = useState(() => {
    // Try to get the selectedItem from location.state first
    const itemFromState = location.state?.selectedItem;
    if (itemFromState) {
      return itemFromState;
    }
    const storedItem = localStorage.getItem("selectedItem");
    return storedItem ? JSON.parse(storedItem) : null;
  });

  useEffect(() => {
    localStorage.setItem("selectedItem", JSON.stringify(selectedItem));
  }, [selectedItem]);

  const fetchCustomers = async (setLoading = true) => {
    if (setLoading) {
      setIsLoading(true);
    }

    try {
      const administratorList = await fetchMembersList(
          businessId,
          jwtToken,
          nextToken,
          type
      );

      if (administratorList) {
        setCustomers([...customers, ...administratorList.data]);
        setNextToken(administratorList.nextToken);
        setError(null);
      }
    } catch (err) {
      setError(err.message);
      console.log(error);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!isUserLoading && adminPortalTranslation) {
      fetchCustomers();
    }
  }, [isUserLoading, adminPortalTranslation]);

  const hasMore = () => {
    return !!nextToken;
  };

  const handleLoadMoreClick = async (e) => {
    e.preventDefault();
    await fetchCustomers(false);
  };

  if (
    adminPortalTranslationIsLoading ||
    !adminPortalTranslation ||
    headers.some((header) => !header)
  ) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error: {adminPortalTranslationError.message}</div>;
  }

  return (
    <>
      <ListHeader
        pageTitle={
          userGroupPrefix === "Admin"
            ? `${selectedItem.businessName} ${adminPortalTranslation.customers}`
            : adminPortalTranslation.customers
        }
        buttonLink={`add`}
      />
      <DisplayList
        type="Customer"
        listData={customers}
        headers={headers}
        columns={customerColumns}
        isLoading={isLoading}
        error={error}
        path={PATH_CONSTANTS.CUSTOMER_LIST}
        hasMore={hasMore}
        handleLoadMoreClick={handleLoadMoreClick}
        showCustomersLink={false}
        translation={adminPortalTranslation}
      />
    </>
  );
};

export default CustomerList;

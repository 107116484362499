import {useContext, useState} from "react";
import {styled} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import {Link, useLocation, useParams} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import "./DisplayList.css";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Flex} from "@aws-amplify/ui-react";
import classes from "../list-header/ListHeader.module.css";
import LanguageContext from "../../contexts/LanguageContext";
import useInternalTranslation from "../../hooks/useInternalTranslation";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DisplayList = ({
  headers,
  listData,
  columns,
  type,
  isLoading,
  hasMore,
  handleLoadMoreClick,
  editRoute,
  translation,
}) => {
  const [searchResult, setSearchResult] = useState("");
  const location = useLocation();
  const customerPage = location.pathname.includes("customers");
  const baseURL = process.env["REACT_APP_PORTAL_API_URL"];
  const { businessId } = useParams();
  const jwtToken = localStorage.getItem("idToken");
  const { t } = useInternalTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [open, setOpen] = useState(false);

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);
  const loadMoreString = language === "en_US" ? "Load more" : "Charger plus";

  const searchCustomer = async (email) => {
    const url = `${baseURL}/businesses/${businessId}/members?email_like=${encodeURIComponent(
      email
    )}`;
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };

    let nextToken = null;
    let allCustomers = [];

    try {
      do {
        const response = await fetch(
          nextToken ? `${url}&nextToken=${nextToken}` : url,
          options
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (Array.isArray(data.data)) {
          allCustomers = allCustomers.concat(
            data.data.filter((obj) =>
              obj.email.toLowerCase().includes(email.toLowerCase())
            )
          );
        }

        nextToken = data.nextToken;
      } while (nextToken);

      return allCustomers;
    } catch (error) {
      console.error("Failed to fetch business info:", error);
      throw error;
    }
  };

  const onSubmit = async ({ email }) => {
    if (email) {
      try {
        const results = await searchCustomer(email);
        if (results.length > 0) {
          setSearchResult(results);
        } else {
          setOpen(true);
        }
      } catch (error) {
        console.error("Failed to search customer:", error);
      }
    }
  };

  const clearSearchResult = () => {
    window.location.reload();
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (searchResult.length > 0) {
    listData = searchResult;
  }

  return (
    <>
      {customerPage && (
        <Box style={{ marginBottom: "24px" }}>
          <FormProvider>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Flex>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={translation.search_by_email}
                      sx={{ mb: 3, backgroundColor: "#F1F3F4" }}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                    />
                  )}
                />
              </Flex>
              <Stack spacing={2} direction="row">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className={classes.button}
                  sx={{ borderRadius: 0 }}
                >
                  {translation.button_search}
                </Button>
                <Button
                  variant="outline"
                  size="large"
                  className={classes.buttonOutline}
                  sx={{ borderRadius: 0 }}
                  onClick={clearSearchResult}
                >
                  {translation.button_clear}
                </Button>
              </Stack>
            </form>
          </FormProvider>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{t("No Customer Found")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("No customer was found with the provided email address")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
      {isLoading ? (
        <>
          <Table size="large">
            <TableHead>
              <TableRow>
                {headers.map((header, indx) => (
                  <StyledTableCell key={"section1-" + header + indx}>
                    {header}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
          <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            sx={{ my: 10 }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Table size="large">
            <TableHead>
              <TableRow>
                {headers.map((header, idx) => (
                  <StyledTableCell key={"section2-" + header + idx}>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listData.length === 0 ? (
                <TableRow>
                  <StyledTableCell colSpan={headers.length}>
                    {`No ${type} found. Please add a ${type}.`}
                  </StyledTableCell>
                </TableRow>
              ) : (
                listData.map((item, idx) => (
                  <StyledTableRow key={item.id + idx}>
                    {columns.map((column, idx2) => {
                      // ****
                      // this is a VERY temporary fix, this component and its API should be refactored
                      // generic components must be as generic as possible
                      // ****
                      if (idx2 === 2) {
                        return (
                          <StyledTableCell key={column + idx2}>
                            {column.dataField(item) === "Active"
                              ? translation["subscription_active"]
                              : translation["subscription_inactive"]}
                          </StyledTableCell>
                        );
                      }

                      return (
                        <StyledTableCell key={column + idx2}>
                          {column.dataField(item)}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell>
                      <Tooltip title="Edit">
                        <Link
                          className="icon"
                          to={
                            !!editRoute
                              ? `${editRoute}/${item.id}`
                              : `${item.id}`
                          }
                          state={{ selectedItem: item }}
                          style={{ padding: "4px" }}
                        >
                          <EditIcon />
                        </Link>
                      </Tooltip>
                      {type === "business" && (
                        <Tooltip title="View Customers">
                          <Link
                            className="icon"
                            state={{ selectedItem: item }}
                            to={`${item.id}/customers`}
                            style={{ padding: "4px" }}
                          >
                            <PeopleIcon />
                          </Link>
                        </Tooltip>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
          {hasMore() && !searchResult ? (
            <Button
              component={Link}
              className="loadMoreLink"
              onClick={handleLoadMoreClick}
              sx={{ mt: 3 }}
            >
              {loadMoreString}
            </Button>
          ) : null}
        </>
      )}
    </>
  );
};

export default DisplayList;

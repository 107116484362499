import { Button, Typography } from "@mui/material";
import { useContext, useRef, useState } from "react";
import Box from "@mui/material/Box";
import LanguageContext from "../../contexts/LanguageContext";
import googleLogin from "../../assets/googleButton.png";
import logo from "../../assets/KnowScam_black.svg";
import GoogleButton from "../../components/google-button";

const useAutoScanFirstTime = (jwtToken, onSkip = () => {}) => {
  const authUrl = process.env.REACT_APP_GOOGLE_AUTH_URL || "";
  const formRef = useRef(null);
  const [openAutoScan, setOpenAutoScan] = useState(false);
  // const { adminPortalTranslation } = useAdminPortalTranslation();
  const { language } = useContext(LanguageContext);

  const protectString =
    language === "en_US"
      ? "Automatically protect your email account with Scamnetic AutoScan"
      : "Protégez automatiquement votre compte de messagerie avec Scamnetic Analyse automatique";
  const getStartedString =
    language === "en_US"
      ? "To get started, sign in with your Google Account."
      : "Pour commencer, connectez-vous avec votre compte Google.";
  const skipThisString =
    language === "en_US" ? "Skip this step" : "Passer cette étape";

  function performGoogleOAuth() {
    formRef.current.submit();
  }

  function showAutoScan() {
    setOpenAutoScan(true);
  }

  function skipAutoScan() {
    onSkip();
  }

  function AutoScanFirstTime() {
    if (openAutoScan) {
      return (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="50px 48px"
          >
            <form ref={formRef} action={authUrl} method="post" noValidate>
              <input type="hidden" name="token" value={jwtToken} />
            </form>
            <img
              src={logo}
              alt="logo"
              style={{ textAlign: "center" }}
              width="100%"
              height="39px"
            />
            <Typography
              fontSize="20px"
              fontWeight="bold"
              style={{ color: "#121217", textAlign: "center" }}
              padding="20px 10px"
            >
              {protectString}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                style={{ color: "#121217", textAlign: "center" }}
                paddingBottom="30px"
              >
                {getStartedString}
              </Typography>
              <GoogleButton onClick={performGoogleOAuth} />
              <Button onClick={skipAutoScan}>{skipThisString}</Button>
            </Box>
          </Box>
        </>
      );
    }
  }

  return {
    showAutoScan,
    AutoScanFirstTime,
  };
};

export default useAutoScanFirstTime;

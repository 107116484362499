import { useContext } from "react";
import LanguageContext from "../contexts/LanguageContext";

const useInternalTranslation = () => {
  const { language } = useContext(LanguageContext);

  function t(english) {
    let result = english;
    if (language === "fr_CA") {
      switch (english?.toLowerCase().trim()) {
        case "please enter a valid phone number":
          result = "Veuillez entrer un numéro de téléphone valide";
          break;
        case "email has improper format":
          result = "Le format de l'email est incorrect";
          break;
        case "email is required":
          result = "L'email est requis";
          break;
        case "first name is required":
          result = "Le prénom est requis";
          break;
        case "last name is required":
          result = "Le nom de famille est requis";
          break;
        case "phone number is required":
          result = "L'phone est requis";
          break;
        case "failed to submit form":
          result = "Échec de la soumission du formulaire";
          break;
        case "back to list":
          result = "Retour à la liste";
          break;
        case "business name is required":
          result = "Le nom de l'entreprise est requis";
          break;
        case "sub domain has improper format":
          result = "Le format du sous-domaine est incorrect";
          break;
        case "sub domain is required":
          result = "Le sous-domaine est requis";
          break;
        case "add product":
          result = "Ajouter un produit";
          break;
        case "edit product":
          result = "Modifier le produit";
          break;
        case "product name is required":
          result = "Le nom du produit est requis";
          break;
        case "product duration is required":
          result = "La durée du produit est requise";
          break;
        case "the duration must be between 1-99":
          result = "La durée doit être comprise entre 1 et 99";
          break;
        case "product duration type is required":
          result = "Le type de durée du produit est requis";
          break;
        case "product description is required":
          result = "La description du produit est requise";
          break;
        case "product price is required":
          result = "Le prix du produit est requis";
          break;
        case "upload of avatar in progress":
          result = "Téléversement de l'avatar en cours";
          break;
        case "upload of logo in progress":
          result = "Téléversement du logo en cours";
          break;
        case "please upload a png or jpeg with a maximum size of 800x800 pixels":
          result =
            "Veuillez téléverser un fichier PNG ou JPEG d'une taille maximale de 800x800 pixels";
          break;
        case "no customer found":
          result = "Aucun client trouvé";
          break;
        case "no customer was found with the provided email address":
          result = "Aucun client n'a été trouvé avec l'adresse email fournie";
          break;
        case "this email is already registered with another user.  choose a different email":
          result =
            "Ce courriel est déjà enregistré avec un autre utilisateur. Veuillez choisir un autre courriel";
          break;
        case "day(s)":
          result = "Jour(s)";
          break;
        case "months(s)":
          result = "Mois";
          break;
        case "years(s)":
          result = "An(s)";
          break;
        case "sign in with google":
          result = "Se connecter avec Google.";
          break;
        default:
          result = english;
          break;
      }
    }
    return result;
  }

  return {
    t,
  };
};

export default useInternalTranslation;

import {useContext, useState} from "react";

import {Box, FormControl, Grid, MenuItem, Select, Typography} from "@mui/material";

import {UserContext} from "../../../../contexts/UserContext";
import LanguageContext from "../../../../contexts/LanguageContext";

import DeleteAccount from "../product-details/delete-account/DeleteAccount";
import ResetPassword from "../reset-password/ResetPassword";
import AutoScanModal from "../../../../features/autoscan-modal/AutoScanModal";

// import API
import {useMemberDetails} from "../../../../api/memberApi";
import IOSSwitch from "./components/IOSSwitch";
import useSnackBar from "../../../snack-bar/useSnackBar";

const Settings = ({ translation }) => {
  const { data, userGroups } = useContext(UserContext);
  const isEnabled = data?.autoScanEnabled;
  const [checked, setChecked] = useState(isEnabled);
  const [modalOpen, setModalOpen] = useState(false);
  const { show } = useSnackBar();

  const jwtToken = localStorage.getItem("idToken");

  const { memberDetails, memberDetailsAreLoading, memberDetailsError, mutate } =
    useMemberDetails(jwtToken);

  // TODO: This should be refactored to use the API
  const { language, setLanguage } = useContext(LanguageContext);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    fetch(`${process.env.REACT_APP_PORTAL_API_URL}/members/langpref`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwtToken,
      },
      body: JSON.stringify({
        memberLanguage: event.target.value
      })
    }).then((response) => {
      console.log(response);
      if (response.ok) {
        if (event.target.value === "en_US") show("Successfully updated language preference")
        else show("Préférence linguistique mise à jour avec succès.");
      } else {
        if (event.target.value === "en_US") show("Failed to update language preference")
        else show("Échec de la mise à jour de la préférence linguistique");
      }
    });
  };

  const handleEnableAutoScan = async () => {
    setModalOpen(true);
  };

  const handleDisableAutoScan = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/autoscan/disable`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwtToken,
      },
    });

    await mutate();
  };

  function canView() {
    if (userGroups.length === 0) return true;
    if (userGroups.includes("Admin")) return true;

    return false;
  }

  if (memberDetailsAreLoading) {
    return <div>Loading user details...</div>;
  }

  if (memberDetailsError) {
    return <div>Error loading user details: {memberDetailsError.message}</div>;
  }

  const autoScanEnabled = memberDetails?.data.autoScanEnabled || false;
  const username = memberDetails?.data.userName;

  return (
    <>
      <AutoScanModal open={modalOpen} handleClose={() => setModalOpen(false)} />

      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Box>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }} gutterBottom>
              {translation.settings}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box display="flex" alignItems="center" marginTop={3}>
            <Typography variant="body1" fontWeight={600}>
              {translation.language}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box display="flex" justifyContent="flex-end" marginTop={3}>
            <FormControl sx={{ width: 250 }}>
              <Select
                value={language}
                onChange={handleLanguageChange}
                inputProps={{ "aria-label": "Without label" }}
                sx={{ bgcolor: "#F1F3F4" }}
              >
                <MenuItem value="en_US">English (US)</MenuItem>
                <MenuItem value="fr_CA">Français</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {canView() && (
          <>
            <Grid item xs={6} md={6}>
              <Box display="flex" alignItems="center" marginTop={3}>
                <Typography variant="body1" fontWeight={600}>
                  {translation.autoScan}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box display="flex" justifyContent="flex-end">
                <IOSSwitch
                    checked={autoScanEnabled ? checked : false}
                    onChange={
                      autoScanEnabled
                          ? handleDisableAutoScan
                          : handleEnableAutoScan
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ m: 1 }}
                />
              </Box>
            </Grid>
          </>
        )}

        <Grid item xs={6} md={6}>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" fontWeight={600}>
              {translation.label_reset_password}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={6} justifyContent="flex-end">
          <Box display="flex" justifyContent="flex-end">
            <ResetPassword translation={translation} />
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" fontWeight={600}>
              {translation.delete_account}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box display="flex" justifyContent="flex-end">
            <DeleteAccount username={username} translation={translation} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;

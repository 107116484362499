import React from "react";
import { Box, Button } from "@mui/material";
const AcceptanceVideo = ({ onSkip }) => {
  return (
    <Box
      marginTop={4}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/9l3Wo4K_zBw?si=iZBUZFD3EmLYhoJ1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mt: 2,
        }}
      >
        <Button variant="contained" color="secondary" onClick={onSkip}>
          Skip
        </Button>
      </Box>
    </Box>
  );
};
export default AcceptanceVideo;

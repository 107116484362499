import {React, useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import {Grid, Typography} from "@mui/material";
import Faq from "../../components/contentful/faq";
import IndustryNewsContainer from "../../components/contentful/industry-news/IndustryNews";
import ResourceCenter from "../../components/contentful/resource-center/ResourceCenter";
import MobileApp from "../../components/mobile-app/MobileApp";

// import contexts
import {UserContext as PortalData} from "../../contexts/UserContext";

import {useAdminPortalTranslation} from "../../api/translationApi";
// import AcceptanceModal from "../../features/acceptance-modal/AcceptanceModal";
import {useFailedGoogleAuth} from "../../hooks/useFailedGoogleAuth";

import useSnackBar from "../../components/snack-bar/useSnackBar";
import useFirstTimeLogin from "../../features/acceptance-modal/useFirstTimeLogin";

const Dashboard = () => {
  const { data } = useContext(PortalData);
  const { show: showFirstTime, FirstTimeLoginModal } = useFirstTimeLogin();

  const [loading, setLoading] = useState(true);

  // this hook detects errors in search params and returns a function to clear it
  const { error, clearError } = useFailedGoogleAuth();

  const { show, CustomSnackBar } = useSnackBar();

  if (error) {
    show(error, "error");
    clearError();
  }

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CustomSnackBar />
      {loading ? <></> :
          <Box overflow="hidden">
            <Container sx={{ mt: 4, mb: 4 }}>
              <Box sx={{ pt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <>
                      <Typography variant="h4" mb={1}>
                        {adminPortalTranslation.welcome_message}
                      </Typography>
                      <Typography variant="body1" mb={3}>
                        {adminPortalTranslation.logged_in_message}
                      </Typography>
                    </>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MobileApp />
                  </Grid>
                </Grid>
              </Box>

              <IndustryNewsContainer />
              <ResourceCenter />
              <Faq />
            </Container>
            <FirstTimeLoginModal/>
          </Box>
      }
      {/**/}
      {/*<AcceptanceModal />*/}
    </Box>
  );
};

export default Dashboard;

import React from "react";
import styles from "./Footer.module.css";
import {Link} from "react-router-dom";

import {useAdminPortalTranslation} from "../../api/translationApi";

const Footer = () => {
  const { adminPortalTranslation } = useAdminPortalTranslation();

  const currentYear = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <p>© {currentYear} Scamnetic</p>
      <Link
        className={styles.footerLink}
        to="https://scamnetic.com/privacy/"
        target="_blank"
        rel="noreferrer"
        aria-label="Privacy Policy"
      >
        {adminPortalTranslation?.footer_1 || "Privacy Policy"}
      </Link>
      <a
        className="footerLink"
        href="https://scamnetic.com/terms-of-service/"
        target="_blank"
        rel="noreferrer"
        aria-label="Terms of Service"
      >
        {adminPortalTranslation?.footer_2 || "Terms of Service"}
      </a>
    </footer>
  );
};

export default Footer;

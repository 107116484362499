import React, {useEffect, useState} from "react";
import {fetchProducts} from "../../utils/api";
import {useForm} from "react-hook-form";
import {Box, InputLabel, MenuItem, Select, Typography} from "@mui/material";

const formInputStyles = {
  bgcolor: "#F1F3F4",
  borderRadius: 2,
  "& .MuiFormHelperText-root": {
    color: "red",
    margin: 0,
    padding: 1,
    backgroundColor: "#fff",
  },
};

const SubscriptionPlan = ({ planType, onChange, translation }) => {
  const [products, setProducts] = useState([]);
  const [, setError] = useState(null);
  const jwtToken = localStorage.getItem("idToken");
  const {
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const getProducts = async () => {
      const { products, error } = await fetchProducts(jwtToken);
      setProducts(products);
      setError(error);
    };

    getProducts();
  }, [jwtToken]);

  return (
    <>
      <Box>
        {/* <Typography variant="h4" sx={{ mb: 3 }} gutterBottom>
          Subscription Plan
        </Typography> */}
        {!products ? (
          <Typography fontWeight="bold">Test</Typography>
        ) : (
          <>
            <InputLabel htmlFor="product">
              {translation.business_product}
            </InputLabel>
            <Select
              fullWidth
              id="product"
              {...register("product", { required: true })}
              defaultValue={planType ? planType?.productDetails?.id : ""}
              sx={formInputStyles}
              onChange={onChange}
            >
              <MenuItem value="" disabled>
                Choose a product
              </MenuItem>
              {products.map((product, index) => (
                <MenuItem key={index} value={product.id}>
                  {product.name}
                </MenuItem>
              ))}
            </Select>
            {errors.product && <span>This field is required</span>}
          </>
        )}
      </Box>
    </>
  );
};

export default SubscriptionPlan;

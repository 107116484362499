import React, {useContext} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {UserContext} from "../../../../contexts/UserContext";
import classes from "../customer-details/CustomerDetails.module.css";

const ProductDetails = ({ translation }) => {
  const { data, loading } = useContext(UserContext);
  // const handleDismiss = () => {
  //   setIsOpen(false);
  //   setChecked(false);
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const startDate = formatDate(data?.productDetails?.startDate);
  const endDate = formatDate(data?.productDetails?.endDate);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleCancellationClick = () => {
    window.open(
      "https://cancellations.scamnetic.com",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Box>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }} gutterBottom>
              {translation["subscription_details"]}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={700}
              sx={{ mb: 1 }}
              gutterBottom
            >
              {data.productDetails.productName ?? "No Product Name Found"}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight={600}>
                  {data.status === "Active"
                    ? translation["subscription_active"]
                    : translation["subscription_inactive"]}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1"></Typography>
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={1}>
            {translation["subscription_startdate"]}:{" "}
            <Box style={{ margin: "0 20px", display: "inline" }}>
              {startDate}
            </Box>
          </Box>
          <Box marginTop={1}>
            {translation["subscription_enddate"]}:{" "}
            <Box style={{ margin: "0 27px", display: "inline" }}>{endDate}</Box>
          </Box>
        </Grid>
        {data?.canCancel && (
          <Grid item xs={6} md={6}>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" fontWeight={600}>
                {translation.label_cancel_subscription}
              </Typography>
            </Box>
          </Grid>
        )}
        {data?.canCancel && (
          <Grid item xs={6} md={6}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={handleCancellationClick}
                className={classes.greyButton}
                variant="contained"
                size="small"
                sx={{ borderRadius: 1, minWidth: "50%" }}
              >
                {translation.button_cancel}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ProductDetails;

import React, { useContext, useState } from "react";
import { Button, Grid } from "@mui/material";
import BusinessForm from "../../components/business-form/BusinessForm";
import UserContext from "../../contexts/UserContexts";
import AdminstratorsList from "../../pages/administrator-list";
import BusinessSettings from "../../components/business-settings/BusinessSettings";
import { useAdminPortalTranslation } from "../../api/translationApi";
import LanguageContext from "../../contexts/LanguageContext";
import useGetBusiness from "../../hooks/useGetBusiness";
import { useParams } from "react-router-dom";

const EditBusiness = () => {
  const { businessId } = useParams();
  const { jwtToken } = useContext(UserContext);
  const { data: businessData } = useGetBusiness(businessId, jwtToken);
  const { userGroupPrefix } = useContext(UserContext);
  const isAdmin = userGroupPrefix === "Admin";
  const { loading } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(
    isAdmin ? "busDetails" : "memberManagement"
  );

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);

  const memberMangementString =
    language === "en_US" ? "Member Management" : "Gestion des membres";

  if (loading) {
    return <div>Loading...</div>;
  }

  const buttonStyle = (tab) => ({
    fontWeight: activeTab === tab ? "bold" : "normal",
  });

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  return (
    <>
      {isAdmin && (
        <Grid container spacing={4} marginBottom="32px">
          <Grid item xs={12}>
            <Button
              color="primary"
              onClick={() => setActiveTab("busDetails")}
              style={{ ...buttonStyle("busDetails"), textTransform: "none" }}
            >
              {adminPortalTranslation["business_business_details"]}
            </Button>

            <Button
              onClick={() => setActiveTab("memberManagement")}
              style={{
                ...buttonStyle("memberManagement"),
                textTransform: "none",
              }}
            >
              {memberMangementString}
            </Button>

            <Button
              onClick={() => setActiveTab("administrators")}
              style={{
                ...buttonStyle("administrators"),
                textTransform: "none",
              }}
            >
              {adminPortalTranslation["administrator_section_header"]}
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {activeTab === "busDetails" && (
            <BusinessForm method="PUT" translation={adminPortalTranslation} />
          )}
          {activeTab === "memberManagement" && (
            <BusinessSettings businessData={businessData} />
          )}
          {activeTab === "administrators" && (
            <AdminstratorsList translation={adminPortalTranslation} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EditBusiness;

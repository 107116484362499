import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import Title from "../../components/title";
import {Link} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import classes from "./Products.module.css";
import {useContext, useEffect, useState} from "react";
import UserContext from "../../contexts/UserContexts";
import {fetchProducts} from "../../utils/api";

import {useAdminPortalTranslation} from "../../api/translationApi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const { jwtToken } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [, setError] = useState(false);

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  const headers = [
    adminPortalTranslation.product_header_id,
    adminPortalTranslation.product_header_name,
    adminPortalTranslation.product_header_status,
    adminPortalTranslation.product_header_price,
    adminPortalTranslation.product_header_created,
    adminPortalTranslation.product_header_action,
  ];

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);
      const { products, error } = await fetchProducts(jwtToken);
      setProducts(products);
      setError(error);
      setIsLoading(false);
    };

    getProducts();
  }, [jwtToken]);

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <Box>
          <Title>{adminPortalTranslation.product_section_header}</Title>
        </Box>
        <Box>
          <Button
            to="/products/add"
            component={Link}
            variant="contained"
            size="large"
            className={classes.button}
            sx={{ borderRadius: 0 }}
          >
            {adminPortalTranslation.button_add || "Add"}
          </Button>
        </Box>
      </Box>
      <Box>
        {isLoading ? (
          <>
            <Table size="large">
              <TableHead>
                <StyledTableRow>
                  {headers.map((header) => (
                    <StyledTableCell key={header}>{header}</StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
            </Table>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              sx={{ my: 10 }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <StyledTableRow>
                  {headers.map((header) => (
                    <StyledTableCell key={header}>{header}</StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!products ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={6}>
                      No products found. Please add a producut.
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  products.map((product) => (
                    <StyledTableRow key={product.id}>
                      <StyledTableCell>{product.id}</StyledTableCell>
                      <StyledTableCell>{product.name}</StyledTableCell>
                      <StyledTableCell>
                        {product.status === "Active"
                          ? adminPortalTranslation["subscription_active"]
                          : adminPortalTranslation["subscription_inactive"]}
                      </StyledTableCell>
                      <StyledTableCell>
                        ${product.price.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(product.createdAt).toLocaleDateString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="Edit">
                          <Link
                            className={classes.icon}
                            to={`${product.id}`}
                            state={{ selectedItem: product }}
                            style={{ padding: "4px" }}
                          >
                            <EditIcon />
                          </Link>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default ProductList;

import useSWR from "swr";
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import LanguageContext from "../contexts/LanguageContext";

const baseUrl = process.env.REACT_APP_I18N_URL;

const translationFetcher = async ([url, language, nameSpace, data]) => {
  const response = await axios.post(
    url,
    { language, nameSpace, data },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (response.status !== 201) {
    throw new Error("Failed to fetch resource");
  }

  return response.data;
};

// unused for now
export const useLoginTranslation = () => {
  // check local storage for "language" key otherwise use en_US
  // const language = localStorage.getItem("language") || "en_US";
  const cookies = document.cookie.split("; ");
  const selectedLanguageCookie = cookies.find((cookie) =>
    cookie.startsWith("selectedLanguage=")
  );

  const language = selectedLanguageCookie
    ? selectedLanguageCookie.split("=")[1]
    : "en_US";

  const { data, error, isLoading } = useSWR(
    [baseUrl + "/i18n/translations", language, "loginSignUp", {}],
    translationFetcher
  );

  return {
    loginTranslation: data ? data : {},
    loginTranslationIsLoading: isLoading,
    loginTranslationError: error,
  };
};

export const useAdminPortalTranslation = () => {
  const { data: userContextData } = useContext(UserContext);
  const { language } = useContext(LanguageContext);

  const userData = {
    firstName: userContextData?.firstName,
    contactPhoneNumber: userContextData?.phoneNumber,
    emailAddress: userContextData?.email,
    acceptedFileTypes: ["csv"],
    maxImageHeight: 800,
    maxImageWidth: 800,
  };

  // alternative: useSWRImmutable
  const { data, error, isLoading } = useSWR(
    [baseUrl + "/i18n/translations", language, "adminPortal", userData],
    translationFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  );

  return {
    adminPortalTranslation: data ? data : {},
    adminPortalTranslationIsLoading: isLoading,
    adminPortalTranslationError: error,
  };
};
